<template>
  <v-dialog :value="printLabelModal" persistent fullscreen @keydown.enter="print">
    <v-card>
      <v-card-title>Print label</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <input class="hidden" id="input" @focus="closePrintLabelModal" type="text" />
            <div class="settings">
              <template v-if="isElectron">
                <label>Printer:</label><select v-model="options.printer">
                  <option v-for="printer of printers" :key="printer.name" :value="printer.name">
                    {{ printer.displayName }}
                  </option>
                </select>
                <label>Aantal:</label><input type="number" v-model="options.copies" />
              </template>
              <label>Met logo:</label>
              <input type="checkbox" v-model="options.showLogo" @change="viewLabel" />
              <label>Naam:</label>
              <input type="text" v-model="thisItem.name" @change="viewLabel" />
              <label>Productiedatum:</label>
              <input type="date" v-model="thisItem.productionDate" @change="viewLabel" />
              <label>Nettogewicht:</label>
              <input type="text" v-model="thisItem.amount" @change="viewLabel" />
              <label>Lotnummer:</label>
              <input type="text" v-model="thisItem.lotNumber" @change="viewLabel" />
              <label>Geboren:</label>
              <input type="text" v-model="thisItem.birthCountry" @change="viewLabel" />
              <label>Gehouden/Gemest:</label>
              <input type="text" v-model="thisItem.residenceCountry" @change="viewLabel" />
              <label>Geslacht:</label>
              <input type="text" v-model="thisItem.slaughterCountry" @change="viewLabel" />
              <label>Leverancier:</label>
              <select v-model="thisItem.supplierNumber" @change="
                generateLotNumber();
              updateItemSupplierAndLotNumber();
              ">
                <option v-for="supplier of suppliers" :key="supplier.supplierNumber" :value="supplier.supplierNumber">
                  {{ supplier.name }}
                </option>
              </select>
              <label></label>
              <v-btn @click="showSettings = !showSettings" color="primary">Instellingen</v-btn>
              <template v-if="showSettings">
                <label>Breedte:</label>
                <input type="number" :value="pointToMm(settings.labelPrinter.pageSize.width)"
                  @change="updateLabelPrinterPageSizeWidth" />
                <label>Hoogte:</label>
                <input type="number" :value="pointToMm(settings.labelPrinter.pageSize.height)"
                  @change="updateLabelPrinterPageSizeHeight" />
                <label>Logo breedte:</label>
                <input type="number" :value="pointToMm(settings.labelPrinter.logoSize.width)"
                  @change="updateLabelPrinterLogoSizeWidth" />
                <label>Logo hoogte:</label>
                <input type="number" :value="pointToMm(settings.labelPrinter.logoSize.height)"
                  @change="updateLabelPrinterLogoSizeHeight" />
                <label>Standaard lettergrootte:</label>
                <input type="number" v-model.number="settings.labelPrinter.styles.defaultStyle.fontSize
                  " />
                <label>Titel lettergrootte:</label>
                <input type="number" v-model="settings.labelPrinter.styles.header.fontSize" />
                <label>Ondertitel lettergrootte:</label>
                <input type="number" v-model="settings.labelPrinter.styles.subHeader.fontSize" />
                <label>Voettekst lettergrootte:</label>
                <input type="number" v-model="settings.labelPrinter.styles.footer.fontSize" />
                <label>Voettekst:</label>
                <textarea rows="5" v-model="settings.labelPrinter.footer"></textarea>
                <label>Marges</label>
                <input type="text" v-model="settings.labelPrinter.margins" />
                <label>Paginastand</label>
                <select v-model="settings.labelPrinter.pageOrientation">
                  <option value="portrait">Portrait</option>
                  <option value="landscape">Landscape</option>
                </select>
                <label>Afbeelding hoogte</label>
                <input type="number" v-model.number="settings.labelPrinter.imageHeight" />
                <label>Afbeelding breedte</label>
                <input type="number" v-model.number="settings.labelPrinter.imageWidth" />
                <label></label>
                <v-btn @click="updateSettings">Update</v-btn>
              </template>
            </div>
          </v-col>
          <v-col cols="6">
            <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintLabelModal" color="primary">Sluit</v-btn>
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { errorHandler, fetchGET } from '../js/functions'
import { viewLabel, printLabel } from "../js/pdfController"
export default {
  name: "printLabelModal",
  props: { printLabelModal: Boolean, item: Object },
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      thisItem: JSON.parse(JSON.stringify(this.item)),
      options: {
        pageSize: "A4",
        copies: 1,
        printer: "",
        showLogo: true,
      },
      showSettings: false,
      settings: {
        labelPrinter: JSON.parse(
          JSON.stringify(this.$store.state.settings.labelPrinter)
        ),
        traceability: JSON.parse(
          JSON.stringify(this.$store.state.settings.traceability)
        ),
      },
      suppliers: [],
    }
  },
  methods: {
    mmToPoints(mm) {
      const MILLIMETERS_IN_INCH = 25.4
      const POINTS_IN_INCH = 72
      const inches = mm / MILLIMETERS_IN_INCH
      let points = inches * POINTS_IN_INCH
      return parseFloat(points.toFixed(2))
    },
    pointToMm(point) {
      const MILLIMETERS_IN_INCH = 25.4
      const POINTS_IN_INCH = 72
      const inches = point / POINTS_IN_INCH
      let mm = inches * MILLIMETERS_IN_INCH
      return parseFloat(mm.toFixed(2))
    },
    updateLabelPrinterPageSizeWidth(e) {
      let width = this.mmToPoints(e.target.value)
      this.settings.labelPrinter.pageSize.width = width
    },
    updateLabelPrinterPageSizeHeight(e) {
      let height = this.mmToPoints(e.target.value)
      this.settings.labelPrinter.pageSize.height = height
    },
    updateLabelPrinterLogoSizeWidth(e) {
      let width = this.mmToPoints(e.target.value)
      this.settings.labelPrinter.logoSize.width = width
    },
    updateLabelPrinterLogoSizeHeight(e) {
      let height = this.mmToPoints(e.target.value)
      this.settings.labelPrinter.logoSize.height = height
    },
    updateSettings() {
      this.$store.commit("updateLabelSettings", this.settings.labelPrinter)
      this.viewLabel()
    },
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printLabel()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printLabel()
      } else {
        document
          .getElementById("iframe")
          .addEventListener("load", this.iframeOnload)
        await this.viewLabel()
        this.iframeLoaded = true
      }
    },
    async printLabel() {
      localStorage.setItem("printLabelOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printLabel(this.thisItem, this.options)
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintLabelModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-label-modal")
    },
    async viewLabel() {
      let data = await viewLabel(this.thisItem, this.options)
      this.dataUrl = URL.createObjectURL(data)
    },
    async fetchSuppliers() {
      try {
        let res = await fetchGET("fetchSuppliers", {})
        this.suppliers = res.suppliers
      }
      catch (err) {
        console.log(err)
      }
    },
    async generateLotNumber() {
      try {
        let shortCodes = new Map()
        shortCodes.set("[*supplier*]", this.thisItem.supplierNumber)
        shortCodes.set("[*year*]", new Date().getFullYear())
        shortCodes.set("[*month*]", new Date().getMonth() + 1)
        shortCodes.set("[*day*]", new Date().getDate())
        shortCodes.set("[*category*]", this.thisItem.category)
        shortCodes.set("[*item*]", this.thisItem.itemNumber)
        let shortCodesMatch = this.settings.traceability.lotNumberFormat.match(/\[\*[\s\S]+?\*\]/g)
        let lotNumberFormat = this.settings.traceability.lotNumberFormat
        for (let i = 0; i < shortCodesMatch.length; i++) {
          //add leading zero
          if (shortCodesMatch[i].includes("supplier") || shortCodesMatch[i].includes("month") || shortCodesMatch[i].includes("day") || shortCodesMatch[i].includes("item")) {
            if (shortCodes.get(shortCodesMatch[i]) < 10) {
              shortCodes.set(shortCodesMatch[i], "0" + shortCodes.get(shortCodesMatch[i]))
            }
          }
          lotNumberFormat = lotNumberFormat.replace(shortCodesMatch[i], shortCodes.get(shortCodesMatch[i]))
        }
        this.thisItem.lotNumber = lotNumberFormat
        await this.updateItemSupplierAndLotNumber()
        await this.viewLabel(this.thisItem, this.options)
      }
      catch (err) {
        errorHandler(err, "generateLotNumber")
      }
    },
    async updateItemSupplierAndLotNumber() {
      try {
        let obj = {
          _id: this.thisItem._id,
          supplierNumber: this.thisItem.supplierNumber,
          lotNumber: this.thisItem.lotNumber
        }
        // await fetchPOST("updateItemSupplierAndLotNumber",obj)
        this.$emit("update-item-supplier-and-lot-number", obj)
      }
      catch (err) {
        errorHandler(err, "updateItemSupplierAndLotNumber")
      }
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron
    },
    printers() {
      return this.$store.state.printers
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printLabelOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printLabelOptions"))
      this.options = obj
    }
    this.thisItem.productionDate = new Date().toISOString().split("T")[0]
    await this.fetchSuppliers()
    await this.generateLotNumber()
  }
}
</script>
<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}

input[type="checkbox"] {
  height: 1em;
  width: 1em;
}

input,
select {
  border: 1px solid black;
}

div.settings label {
  text-align: right;
}

#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>